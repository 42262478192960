<template>
  <!-- 隐私 -->
  <div class="hello">
    <div class="main min-height">
      <!-- 全职 -->
      <div v-if="edition == 0">
        欢迎您使用我们的产品和服务！我们非常重视您的隐私保护和个人信息保护。24猎在向用户提供职业及发展机会的同时，我们尊重并保护所有使用24猎服务的用户的个人信息，并会尽全力保护您的个人信息安全可靠。24猎严格遵守法律法规，遵循以下隐私保护原则，以期为您提供更加安全、可靠的服务。
        <br />一、引言
        <br />为了切实保护24猎用户隐私权，优化用户体验，24猎根据现行法规及政策，制定本《24猎隐私政策》。本政策将详细说明24猎在获取、管理及保护用户个人信息方面的政策及措施。本隐私政策制定的法律依据为《中华人民共和国消费者权益保护法》、《中华人民共和国网络安全法》、《中华人民共和国电子商务法》、《信息安全技术个人信息安全规范》以及其他涉及公民个人信息的相关法律法规。通常，我们会基于本隐私政策提示的功能收集您的个人信息。某些情况下，如果涉及其他信息的收集我们会单独向您出示个人信息保护说明条款。
        <br />《24猎隐私政策》适用于（1）您使用24猎网站（hr24.com.cn）；（2）您关注、使用24猎的微信公众号、24猎企业版小程序和24猎精英版小程序时，我们收集和使用您的个人信息及其他相关信息。我们将通过网站、微信公众号和微信小程序等多种形式向您提供服务，这些服务将实现个人求职和企业招聘的基础功能，具体包括注册、登录、用户寻找职位和投递简历、企业处理简历和实施招聘。
        <br />本隐私政策旨在向您说明我们如何收集、使用、分享以及保护您的个人信息及其它相关信息。请您在使用24猎服务前务必仔细阅读并明确您已经充分理解、接受本隐私政策的内容，希望您可以根据自己的理解做出合适的选择。当您向我们提供您的个人信息或开始使用我们的产品或服务时，即表示您已知悉并同意我们在本隐私政策中所述内容。在我们更新本《24猎隐私政策》后，您继续使用我们的产品和/或服务，即意味着您同意本《24猎隐私政策》(含更新版本)内容，并且同意我们按照本隐私政策收集、使用、保存和共享您的相关信息。此外，当我们将您的个人信息用于本隐私政策未涵盖的用途时，我们会事先征求您的同意。在使用过程中，请谨慎考虑是否向我们提供这些信息，我们将尽最大努力保护好您的个人信息。
        <br />
        <br />二、我们如何收集和使用您的信息
        <br />(一)基本功能及相关必要个人信息
        <br />在您使用24猎提供的以下服务或功能过程中，我们将收集以下必要的相关个人信息，以及部分您可以选择提供的个人信息。
        <br />1、招聘者使用账号
        <br />您在使用24猎提供的服务时，首先需要成为我们的用户。当您注册/申请24猎账户时，您需要向我们提供您准备使用的联系人、您的手机号码、营业执照等相关资质和信息，我们可能会通过发送短信验证码的方式来验证您的身份是否有效。
        <br />2、企业认证
        <br />您要使用招聘服务的，需通过审核认证。您需要向我们提供企业相关信息，包含但不限于公司名称、营业执照、联系人、联系方式等，以证明您可以代表该单位进行招聘。如果您提交的单位系首次在24猎上发布招聘信息，那么您还需要提供营业执照以验证招聘单位的合法性。审核通过后，您可以使用账号发布职位。
        <br />3、关注并使用24猎微信公众号、小程序的授权信息
        <br />为了保障用户求职安全，当您作为求职用户使用24猎时，为保存登录信息并在不同设备登录时能同步数据，我们将需要您授予我们从微信获得某些信息的权限，如使用者微信登录的唯一标识、头像、昵称。我们会通过弹窗申请获取您的登录授权；经您授权同意后，再获取您的信息授权，例如您的微信名、头像、地区、姓名、性别、学历等。
        <br />当您关注、使用我们的微信公众号时，我们会收集您的微信昵称、头像；当您使用该微信小程序时，我们可能会收集您的微信UnionID，OpenID，微信昵称，微信头像，微信地区，微信性别，登录记录信息。当您使用微信公众号或微信小程序的特定功能或服务时，您需要使用微信账号快速登录，当您选择后，我们可能会收集您的手机号码，对于此类信息，我们将按照微信公众号和微信小程序的个人信息收集规则，根据相应提示获取您的同意。
        <br />4、创建简历
        <br />在您授权成功后，首先您需要完善简历（公开），设置您的头像（选填）、填写您的真实姓名、性别、学历、年龄、联系方式、婚否（选填）、邮箱（选填）、工作期望（期望地区、期望薪资、期望岗位）、工作经历（选填）、教育经历（选填）、自我评价等简历信息。此外，您可以自行选择对简历进行修改和完善。在工作经历中，您可以选择添加您过去的工作经历，我们可能收集您的工作过的公司名称、职位名称、在职时间、在职薪资。24猎将根据您的以上信息，利用算法为您推荐可能合适您的岗位、将您推荐给可能合适的招聘用户。
        <br />您可以设置简历信息是否全部招聘企业可见或操作简历上下架，设置非全部招聘企业可见或下架简历后，招聘用户无法再浏览您的简历内容。请注意：若您主动查看某一职位或与该企业在线沟通或投递简历，您隐藏的简历将会对其可见。如您选择向“所有招聘企业”可见，则您授权24猎上相应服务的招聘企业或人员可以通过我们的简历数据库找到您的简历。为了能够更好的为您提供求职服务，建议您如实填写简历信息，如因您自身填写的简历虚假等问题引起的任何争议，您应自行承担。我们亦会对使用简历的招聘方进行资质审核，但请您知悉，我们仅能进行形式上的审核，对于招聘企业与您线下发生的任何法律纠纷（包括但不限于招聘企业或人员错误或非法使用前述简历信息等），24猎不承担法律责任。
        <br />尽管
        24猎已经作了相应的安全防范措施，仍可能不可避免某一第三方躲过了我们的安全措施并进入我们的数据库查找到您的简历。24猎认为在您把您的简历放入我们的数据库时，您已经意识到并同意承担这样的风险。对于因此而引起的任何法律纠纷，24猎不承担任何法律责任。
        <br />当您使用我们的服务进行简历投递时，我们会收集您的投递记录，方便您能查看投递职位的进展等。
        <br />5、推荐服务
        <br />（1）您可以通过24猎产品的推荐页，浏览到可能会满足您的需求或期望的招聘信息或求职用户。我们可能会保留您的使用习惯，避免您再重复输入或为您展示与您搜索内容相关岗位或求职用户。请您注意，我们会将您的搜索信息作为您的个人信息，与您的历史搜索记录一同按照本隐私政策对其进行处理与保护。
        <br />（2）为了使您高效地求职，对您感兴趣的招聘单位可向您发出添加好友、在线聊天、下载您的简历并与您进行电话沟通。您也可以选择您感兴趣的招聘单位主动投递简历和在线聊天。
        <br />温馨提示：24猎无法保证推荐算法的绝对精准，也无法确保您百分百满意24猎的推荐服务，无论有偿还是免费。
        <br />6、求职安全保障
        <br />我们会使用商务场景下的通讯信息（包括您的手机号、微信、沟通记录），用于处理您的投诉举报、申诉；我们会使用您的身份信息，如账号ID、密码、手机号对您进行身份核验、安全检测等。
        <br />7、反馈
        <br />我们可以通过24猎接收您所提供的反馈信息。通过此类链接收到的反馈信息的全部相关权利（包括但不限于复制、转引该等信息的权利）属于我们所有。由于反馈信息由您主动提供，因此视为您已同意我们获取并在合法范围内使用此类反馈信息。我们可能通过您所提供的联系方式与您联系并进一步获得相关反馈信息。
        <br />8、信息使用
        <br />（1）为实现我们最基本的人力资源服务，即帮助个人寻找职业，帮助雇主寻找雇员，我们需要收集并使用您的求职信息，以帮助个人或雇主查看对方的信息，并达成雇佣的合意。
        <br />（2）当您填写简历之后可将简历向特定企业投递，我们将会把您的简历信息提供给您所投递的招聘单位，供其选拔；对您感兴趣的招聘单位将获得您的联系方式或对您电话邀约或在线沟通。
        <br />（3）当您登录授权信息或把简历设置为“公开”时，招聘单位可主动搜索您的简历信息，以增加您的求职机会，我们不会在搜索结果中公开您的联系方式；对您感兴趣的招聘单位可以与您在线沟通、添加微信或下载您的简历。
        <br />（4）在不透露单个用户隐私资料的前提下，24猎有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。24猎可能会对网络服务使用情况进行统计。同时，24猎可能会与公众分享这些统计信息，以展示我们服务的整体使用趋势。这些统计信息不包含您的任何身份识别信息。
        <br />（二）改进产品和保障求职招聘安全的相关信息
        <br />为了增强24猎的求职招聘等过程中的安全性，尽可能保障您或其他用户在招聘求职过程中的人身、财产安全免遭侵害，我们会收集必要的不涉及到您的个人身份等隐私的信息，包括：我们可能会收集您网络服务相关的日志信息，如IP地址、某按钮的点击使用时间和频率、某页面的使用时间和频率、某些关键配置的选项值、崩溃日志、错误日志，用于将您感兴趣的职位、求职用户或求职招聘过程中您可能需要的服务信息展示给您，同时有可能用于统计我们产品的用户数量、分析产品的使用状况、网页升级改版、浏览器兼容性检测、排查崩溃原因、减少崩溃情况。
        <br />（三）需要您授权同意调取系统权限的情形
        <br />基于保护招聘求职过程中的求职者人身安全之考虑，以及满足互联网平台服务普遍存在的风控需求，24猎的附加业务功能可能需要您先同意我们调取相关的系统权限以使用您的个人信息。若您进行权限即代表您授权我们收集和使用这些个人信息。
        <br />（四）通过第三方SDK收集信息的情形
        <br />1、为保障我们客户端的稳定运行、功能实现，使您能够使用和体验更丰富的服务及功能，我们的应用中会嵌入授权合作伙伴的SDK
        或其他类似的应用程序。
        <br />2、我们会对前述应用程序接口（API）、软件工具开发包（SDK）及授权合作伙伴进行严格的安全检测，并约定严格的数据保护措施，令其按照本政策以及其他任何相关的保密和安全措施来处理个人信息。
        <br />3、此外，当您通过本平台接入的第三方服务时，您的信息将适用该第三方的隐私政策，建议您在接受相关服务前阅读并确认理解相关协议。
        <br />4、对我们与之共享用户信息的公司、组织和个人，我们会与其签署严格的保密协议以及信息保护约定，要求他们按照我们的说明、本隐私权政策以及其他任何相关的保密和安全措施来处理用户信息。
        <br />（五）征得授权同意的例外
        <br />根据相关法律法规的规定，在以下情形中，我们可以在不征得您的授权同意的情况下收集、使用一些必要的个人信息：
        <br />(1)与国家安全、国防安全直接相关的；
        <br />(2)与公共安全、公共卫生、重大公共利益直接相关的；
        <br />(3)与犯罪侦查、起诉、审判和判决执行等直接相关的；
        <br />(4)出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
        <br />(5)所收集的个人信息是您自行向社会公众公开的；
        <br />(6)从合法公开披露的信息中收集到您的个人信息，如从合法的新闻报道、政府信息公开、公开数据库、社交媒体、第三方服务供应商及其他合作伙伴等渠道；
        <br />(7)根据您的要求签订和履行合同所必需的；
        <br />(8)学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
        <br />(9)用于维护24猎产品和/或服务的安全稳定运行所必需的，包括发现、处置产品或服务的故障；
        <br />(10)法律法规规定的其他情形。
        <br />我们将努力核查信息来源的合法性，并保护您的个人信息。我们不会将改善服务质量、提升用户体验作为单独的目的收集您的个人信息。如果您对我们收集和使用您的个人信息有任何疑问或需要提供进一步的信息，请通过本《24猎隐私政策》公布的联系方式与我们联系。根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
        <br />
        <br />三、我们如何使用cookie和同类技术
        <br />当您使用我们的小程序或网站时，我们可能通过Cookies和同类技术收集您的设备型号，操作系统、设备标识符、登录IP地址信息，以及缓存您的浏览信息、点击信息，以查看您的网络环境。通过Cookies，我们可以在您访问网站时识别您的身份，并不断优化网站的用户友好程度，并根据您的需要对网站做出调整。您也可以更改浏览器的设置，使浏览器不接受我们网站的Cookies，但这样可能会影响您对网站的部分功能的使用。
        <br />网页上常会包含一些电子图象（称为“单像素” GIF 文件或 “网络
        beacon”），使用网络beacon可以帮助网站计算浏览网页的用户或访问某些cookie，我们会通过网络beacon收集您浏览网页活动的信息，例如您访问的页面地址、您先前访问的援引页面的位址、您停留在页面的时间、您的浏览环境以及显示设定等。
        <br />我们不会将上述技术收集的信息用于本隐私政策所述目的之外的任何用途。请您理解，我们的某些服务只能通过使用“Cookie”才可得到实现。如果您的浏览器或浏览器附加服务允许，您可以在相关的页面修改对Cookie的接受程度，但这一举动在某些情况下可能会影响您安全访问24猎相关网站和使用24猎提供的服务。
        <br />
        <br />四、我们如何共享、转让、公开披露您的个人信息 <br />（一）共享
        <br />我们将在以下情形中向第三方分享或提供您的个人信息：
        <br />1、在获得您事先授权的前提下，我们可能会将您的信息分享给我们的招聘单位、第三方服务提供商或合作伙伴，如我们根据您的授权将相关内容分享给您授权的微信等社交媒体服务提供商。由于我们提供的是招聘服务，查阅您的简历的招聘单位数量直接影响了您的求职机会，您可以直接将简历投递给招聘单位，也可以选择在简历|隐私设置中设定为“公开”，使招聘单位能根据需求搜索到您的简历，当您将其设置为“保密”后，您的简历则无法被招聘单位搜索。
        <br />2、为了向您提供服务或实现上述信息使用的目的，我们可能将您的个人信息分享给我们的招聘单位、合作伙伴或第三方服务提供商，第三方服务提供商可能将您的个人信息存储于其服务器上。例如，在您投递简历时，将您的简历信息分享给招聘单位。第三方通过嵌入代码、插件等方式获取个人信息的前提是获得您的明确授权。
        <br />3、法律法规或政府要求
        <br />根据法律法规，以下情形中收集和使用您的个人信息无需征得您的同意：
        <br />（1）与履行法律法规规定的义务相关的；
        <br />（2）与国家安全、国防安全直接相关的；
        <br />（3）与公共安全、公共卫生、重大公共利益直接相关的；
        <br />（4）与刑事侦查、起诉、审判和判决执行等直接相关的；
        <br />（5）出于维护您或其他个人的生命、财产等重大合法权益相关但又很难得到本人授权同意的；
        <br />（6）所涉及的个人信息是您自行向社会公众公开的；
        <br />（7）根据您要求签订和履行合同所必需的；
        <br />（8）从合法公开披露的信息中收集个人信息的，如通过合法的新闻报道、政府信息公开等渠道；
        <br />（9）维护所提供的24猎产品与/或服务的安全稳定运行所必需的，例如发现、处置24猎产品与/或服务的故障；
        <br />（10）法律法规规定的其他情形。 <br />4、紧急危险
        <br />为免除我们及员工、服务的用户或公众在生命、身体或财产方面的紧急危险时，我们可能在法律法规要求或允许的范围内分享您的信息。
        <br />5、合并、收购或资产出售
        <br />为合并、收购或资产出售等交易的需要，我们可能会将您的个人信息进行转让，在转移您的个人信息前，我们会给予您适当通知，并确保您的个人信息在转让后得到与本隐私政策相当的保护。
        <br />6、第三方技术服务
        <br />为提供和优化我们的服务，我们的产品中内嵌了第三方的SDK，包括但不限于阿里云、高德地图。在获取您的授权后，这些第三方SDK在配合我们向您提供更全面的服务的同时，可能会收集您的个人信息，上述所有信息将去标识化传输。为保护您的隐私，我们会对第三方进行尽职调查、也会采取技术手段严格控制第三方的信息获取行为，但请您理解我们无法完全对第三方的行为负责，因此为保证您的利益，我们强烈建议您花时间阅读第三方的隐私政策，以便提前全面了解相关第三方的隐私实践。
        <br />（1）第三方登录（微信SDK、中国移动、中国电信、中国联通）
        <br />为了实现第三方登录功能和一键快速登录功能，在您授权同意后，微信SDK可能获取访问您的读写入外部存储、WiFi权限、网络状态、电话状态、检索正在运行的应用，用于保证对应功能的正常使用。
        <br />（2）支付（微信）
        <br />为实现用户在线支付购买增值服务产品，在您授权同意后，微信可能获取访问您的网络状态、读取电话状态、Wi-Fi状态、检索正在运行的应用，确保您在服务中正常使用在线支付的功能。您可选择关闭相应的授权，但可能造成您无法购买我们的增值产品或服务。
        <br />（3）定位（腾讯地图SDK）
        <br />为了确保求职者获取招聘单位的准确地址，在您授权同意后，腾信地图SDK可能获取访问您所在的城市、地区以及位置信息、网络状态、读写外部存储权限、蓝牙、Wi-Fi状态、检索正在运行的应用、读取电话状态权限。您可选择关闭相应的授权，但可能造成您无法接收附近的职位信息。
        <br />（4）用户运营（阿里云）
        <br />为了进行系统异常上报和运营统计，以及快速发现并解决异常，在您授权同意后，阿里云可能获取写入外部存储、查看WiFi状态、查看网络连接，监控程序闪退、记录日志权限。
        <br />注：请您知悉，出于安全考虑，24猎产品与/或服务会采用私有化部署的方式，减少第三方对您个人信息的获取。我们采取了本地化部署方式接入用于上报用于操作行为，做数据分析使用。
        <br />(二)转让
        <br />我们不会将您的个人信息转让给除24猎外的任何公司、组织和个人，但以下情形除外：
        <br />1、事先获得您的明确授权或同意；
        <br />2、满足法律法规、法律程序的要求或强制性的政府要求或司法裁定；
        <br />3、如果我们或我们的关联公司涉及合并、分立、清算、资产或业务的收购或出售等交易，您的个人信息有可能作为此类交易的一部分而被转移，我们将确保该等信息在转移时的机密性，并要求新的持有您个人信息的公司、组织继续受此个人信息保护政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
        <br />（三）公开披露 <br />我们仅会在以下情形下，公开披露您的个人信息：
        <br />1、获得您的明确同意；
        <br />2、基于法律法规、法律程序、诉讼或政府主管部门强制性要求下，我们可能会对上述监管部门披露您的个人信息。
        <br />（四）例外情形
        <br />在以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
        <br />1、与国家安全、国防安全直接相关的；
        <br />2、与公共安全、公共卫生、重大公共利益直接相关的；
        <br />3、与犯罪侦查、起诉、审判和判决执行等直接相关的；
        <br />4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
        <br />5、您自行向社会公众公开的个人信息；
        <br />6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
        <br />7、根据个人信息主体要求签订和履行合同所必需的；
        <br />8、用于维护所提供的产品或服务的安全稳定运行所必需的，包括发现、处置产品或服务的故障；
        <br />9、法律法规规定的其他情形；
        <br />10、根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
        <br />
        <br />五、我们如何存储及保护您的个人信息 <br />(一)信息存储
        <br />1、信息存储
        <br />您在使用24猎产品及服务期间，我们将持续为您保存您的个人信息。如果您注销账户账号或主动删除上述信息，我们将依据《网络安全法》等相关法律法规的规定
        <br />保存您的信息。在您注销帐户或主动删除上述信息后，我们不会再对您的个人信息进行商业化使用，但我们可能会对您的个人信息进行删除或匿名化处理后使用。
        <br />2、保存地域
        <br />我们承诺，目前您的个人信息仅储存于中华人民共和国境内。如在符合适用法律规定的情形下因业务需要向境外传输个人信息的，我们会事先征得您的同意，并向您告知用户信息出境的目的、接收方、安全保障措施、安全风险等情况。
        <br />3、例外情况
        <br />一般而言，我们仅为实现目的所必需的合理时间保留您的个人信息，下列情况下，我们有可能因需符合法律要求，更改个人信息的存储时间：
        <br />（1）为遵守法律法规等有关规定的适用；
        <br />（2）为遵守法院判决、裁定或其他法律程序的规定；
        <br />（3）为遵守相关政府机关或法定授权组织的要求；
        <br />（4）我们有理由确信需要遵守法律法规等有关规定；
        <br />（5）为执行相关服务协议或本《24猎隐私政策》维护社会公共利益；
        <br />（6）为保护我们及我们的客户、用户或雇员的人身财产安全；
        <br />（7）其他合法权益所合理必需的用途。
        <br />4、当我们的产品或服务发生停止运营的情形时，我们将按照法律规定向用户进行通知，通知方式包括但不限于消息推送、平台公告等，并在合理期限内删除或匿名化处理您的个人信息。
        <br />(二)保护措施
        <br />1、我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的浏览器与服务器之间交换数据时受SSL协议加密保护；我们同时对24猎网站提供HTTPS协议安全浏览方式；我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全培训课程，加强员工对于保护个人信息重要性的认识。
        <br />2、我们从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。目前，我们的重要信息系统已经通过网络安全等级保护的测评。
        <br />3、我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本隐私政策所述目的所需的期限内保留您的个人信息（除非法律有强制的存留要求或双方另有约定）。
        <br />4、互联网并非绝对安全的环境，使用24猎服务时，您可以通过我们的服务建立联系和相互分享。当您通过我们的服务创建交流、分享时，您可以自主选择沟通、分享的对象，作为能够看到您联络方式、交流信息或分享内容等相关信息的第三方。
        <br />5、在使用24猎服务进行沟通时，请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息尤其是您的账号或密码发生泄露，请您立即通过本隐私政策的中公布的联系方式与我们联系，以便我们根据您的申请采取相应措施。
        <br />请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息，如您在评论、沟通时选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。
        <br />(三)安全事件通知
        <br />1、我们会制定网络安全事件应急预案，及时处置系统漏洞、计算机病毒、网络攻击、网络侵入等安全风险，在发生危害网络安全的事件时，我们会立即启动应急预案，采取相应的补救措施，并按照规定向有关主管部门报告。
        <br />2、个人信息泄露、毁损、丢失属于公司级特大安全事件，我们会负责定期组织工作组成员进行安全预案演练，防止此类安全事件发生。若一旦不幸发生，我们将按照最高优先级启动应急预案，由安全部、政府关系部、法务部等多个部门组成应急响应小组，在最短时间内追溯原因并减少损失。
        <br />3、在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时通过软件内部提醒、邮件、短信或电话等方式向您告知安全事件的基本情况和可能的影响、我们采取或将要采取的处理措施、您可自主防范和降低的风险的建议、对您的补救措施等。我们将及时将事件相关情况通过上述方式告知您，难以逐一告知时我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
        <br />
        <br />六、您的权利及选择
        <br />按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：
        <br />(一)访问和修改您的个人信息
        <br />您有权随时登录并访问您的24猎账号，查看您的个人信息。如您发现我们收集、存储、使用、披露的您的个人信息有错误或不完整的，或者在法律法规规定的其他情况下，您可以通过如下方式进行更正或补充完善。
        <br />您可以通过点击“简历”对您的简历信息（适用于求职者用户）、个人资料（适用于招聘者用户）进行访问或更正。已经完成审核认证的招聘用户，不可修改招聘企业名称。您可以通过“我的”对简历进行上下架。
        <br />如您希望访问或更正您的其他个人信息，或在行使上述权利过程中遇到困难，您可以通过本政策公布的联系方式与我们联系，我们将在核实您的身份后及时响应您的请求，但法律法规另有规定的或本政策另有约定的除外。
        <br />(二)删除您的个人信息
        <br />当您不再使用我们的产品或服务（适用于招聘用户）且要求删除您的个人信息（适用于求职者用户）时，或我们违反法律法规或与您的约定收集、使用、与他人分享您的个人信息时，您有权要求删除您的个人信息。为了防止错误操作导致信息丢失，当您行使删除权力的时候您需要联系客服进行处理，当我们决定响应您的删除请求时，我们将尽快从24猎服务中删除信息，使您的信息不可见。
        <br />请您知悉，我们可能不会将您需要删除的信息立即从备份系统中删除，但会在备份系统更新时进行删除。您作为求职者用户直接向招聘者企业投递的简历或被企业搜索已下载过的简历，以及您与他人在本平台上（如通过动态消息或群组）所分享的信息，即使您删除信息后，我们可能不会立即删除，因为我们无法从第三方服务器删除从本平台复制的信息和您与他人在本平台所交流的信息。
        <br />在以下情形中，您可以向我们提出删除个人信息的请求，您可以通本政策公布的联系方式随时与我们联系：
        <br />1、如果我们违反法律法规或与您的约定收集、使用、与他人共享或转让您的个人信息。
        <br />2、如果我们违反法律法规规定或与您的约定，公开披露您的个人信息，您有权要求我们立即停止公开披露的行为，并发布通知要求相关接收方删除相应的信息。
        <br />3、我们没有征求您的明确同意，收集了您的个人信息。
        <br />4、我们违反了与您的约定来使用和处理您的个人信息。
        <br />5、您申请注销24猎帐号、或者在24猎中设置了简历下架。
        <br />6、我们停止对您提供服务。 <br />(三)注销您的账号
        <br />您可以通过本《24猎隐私政策》公布的联系方式，申请注销24猎账号。我们会
        <br />立即响应您的注销申请。在您主动注销账号之后，我们将停止为您提供产品或服务，并根据法律的要求删除您的个人信息，或对其进行匿名化处理。
        <br />(四)提前获知产品和服务停止运营
        <br />24猎愿一直陪伴您，若因特殊原因导致24猎被迫停止运营，我们将按照法律法规的要求，在产品和/或服务的主页面向您发送消息通知或其他合适的能触达您的方式通知您，并将停止对您个人信息的收集，同时会按照法律规定对所持有的您的个人信息进行删除或匿名化处理等。
        <br />(五)响应您的上述请求
        <br />1、为保障安全，您需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
        <br />2、对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
        <br />(六)响应请求的例外
        <br />在以下情形中，按照法律法规要求，我们将无法响应您的上述请求：
        <br />1、与国家安全、国防安全直接相关的；
        <br />2、与公共安全、公共卫生、重大公共利益直接相关的；
        <br />3、与犯罪侦查、起诉、审判和执行判决等直接相关的；
        <br />4、证据表明您可能存在明显恶意、滥用权力及占用平台资源、辱骂客服人员等相关情形的（如您的请求将危害公共安全）；
        <br />5、您和其他人合法权益，或您的请求超出了一般技术手段和商业成本可覆盖的范围）；
        <br />6、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；
        <br />7、涉及商业秘密的。
        <br />
        <br />七、我们如何处理未成年人的个人信息
        <br />24猎非常重视对未成年人信息的保护。基于我们的产品、网站和服务的性质，24猎拒绝年龄未满16周岁的用户使用24猎。
        <br />
        <br />八、您的个人信息如何进行跨境转移
        <br />您的个人信息将只存储在位于中国的服务器上，您的信息将不会被我们主动传输到境外。
        <br />
        <br />九、本隐私政策的更新
        <br />我们可能适时修订本《24猎隐私政策》内容。如该等变更会导致您在本《24猎隐私政策》项下权利的实质变化，我们将在变更生效前，通过系统推送短信或页面公告等方式通知您。若您不同意该等变更应停止使用24猎产品和服务，或通过本《24猎隐私政策》公布的联系方式要求暂时封停您的账号。若您继续使用我们的产品或服务，即表示您同意受修订后的本《24猎隐私政策》的约束；本《24猎隐私政策》所指的重大变更包括但不限于：
        <br />1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
        <br />2、个人信息共享、转让或公开披露的主要对象发生变化；
        <br />3、您参与个人信息处理方面的权利及其行使方式发生重大变化；
        <br />4、其他可能对您的个人信息权益产生重大影响的变化时；
        <br />5、个人信息出境情况发生变更时。
        <br />
        <br />十、如何联系我们
        <br />如果您对本《24猎隐私政策》及我们对您的个人信息的处理有任何疑问、意见、建议，请通过mail@hr24.com.cn与我们联系。一般情况下，我们会在15日内（国家法定节假日顺延）对您的请求予以答复。
        <br />
        <br />十一、争议解决
        <br />本《24猎隐私政策》与《24猎用户协议》共同构成您使用24猎服务的基本协议文件。本《24猎隐私政策》适用中华人民共和国现行法律法规。与本隐私政策相关的争议，如果您对我们的回复或解决方案不满意，需要通过法律途径解决的，应将该争议提交至北京仲裁委员会，其仲裁裁决是终局的。
        <br />附录：定义
        <br />本《24猎隐私政策》中使用的特定词语，具有如下含义：
        <br />1、“我们”或“24猎”，指北京三门课科技有限公司。
        <br />2、“您”或“用户”，指使用北京三门课科技有限公司运营的平台产品或服务的用户以及收费服务的购买方。
        <br />4、“24猎，指（hr24.com.cn）24猎PC端、24猎企业版微信小程序寄24猎精英版小程序。
        <br />5、“个人信息”，指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。个人信息包括个人基本信息、个人身份信息、、网络身份标识信息、个人健康生理信息、个人教育工作信息、个人财产信息、个人通信信息、联系人信息、个人上网记录、个人常用设备信息、个人位置信息等。为免疑义，个人信息包括但不限于个人敏感信息。
        <br />7、“个人信息主体”，指个人信息所标识的自然人。
        <br />8、“个人敏感信息”，指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。个人敏感信息包括个人财产信息、个人健康生理信息、个人生物识别信息、个人身份信息、网络身份标识信息等。
        <br />9、“设备标信息”，是指设备型号、操作系统版本、设备设置、MAC地址及IMEI、IDFA、OAID及其他设备标识符。
        <br />10、“位置信息”，是指IP地址、GPS位置、Wi-Fi接入点、蓝牙和基站等。
        <br />11、“去标识化”，指通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别个人信息主体的过程。
        <br />12、“匿名化”，指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。
        <br />13、“中国”或“中国境内”，指中华人民共和国大陆地区，仅为本《个人信息保护政策》之目的，不包含香港特别行政区、澳门特别行政区和台湾地区。
        <br />
      </div>
      <!-- 零工 -->
      <div v-else>
        个人信息保护及隐私政策 前言<br />
        1、我们非常重视用户个人信息的隐私保护。本隐私政策适用于24猎手机客户端和关联开放平台产品及服务。请您务必认真阅读本政策，在确认充分了解并同意后使用24猎，您在使用我们的服务时，我们可能会收集和使用您的相关信息。鉴此，依据适用法律法规要求，采取相应安全保护措施尽力保护您的个人信息安全可控，北京三门课科技有限公司（以下简称“我们“）希望通过《24猎隐私保护政策》（以下简称《隐私政策》）向您说明，在使用我们的服务时，我们如何收集、使用、储存和分享这些信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。本《隐私政策》与您所使用的服务息息相关，希望您仔细阅读，在需要时，按照本《隐私政策》的指引，作出您认为适当的选择。本《隐私政策》中涉及的相关技术词汇，我们尽量以简明扼要的表述以便您理解。<br />
        2、您使用或继续使用我们的服务，即意味着同意我们按照本《隐私政策》收集、使用、储存和分享您的相关信息。<br />
        3、本隐私政策将向您说明24猎平台可能需要调取的设备权限、调用的目的或其对应的业务共功能、调用前是否询问以及用户关闭相应的权限的方式。<br />
        4、本隐私政策向您说明为保护用户个人信息安全，24猎所采用的安全制度及技术措施，如制定相应规范制度、数据加密存储、加密传输、全员签署保密协议、严格控制访问权限等，来确保您的个人信息不泄露、丢失、毁损或被未经授权的访问和使用。<br />
        5、24猎将严格按照本隐私政策说明的使用场景或者共享您的个人信息，如果超过本隐私政策说明的范围，我们会再次向您告知并征得您的明示同意。<br />
        6、如对本《隐私政策》或相关事宜有任何问题，请通过qudao24lie@126.com与我们联系。<br />
        我们可能会收集的信息<br />
        我们提供服务时，可能会收集、储存和使用下列与您有关的信息。如果您不提供相关信息，可能成为我们的用户或无法享受我们提供的某些服务，或者无法达到相关服务拟达到的效果。<br />
        当您通过微信登录方式登录24猎平台，我们会需要获取您在微信平台的OpenID。我们以此识别您在24猎平台的网络身份，并为您提供24猎平台服务。如果您拒绝提供手机号码，24猎平台将无法为您创建账号并提供服务。<br />
        您提供的信息<br />
        您在使用我们的服务时，需要向我们提供的相关个人信息，例如电话号码、银行卡信息、身份证等。我们还需要查询您的信用信息、是否有犯罪记录、是否有相关不良行为记录或其他不适宜从事零工服务的情形。如您不提供上述信息，24猎平台将无法为您提供服务。<br />
        如果您是企业用户，必须向我们提供姓名、企业营业执照等信息，我们收集上述信息是基于法律法规要求以及保护平台用户人身安全之需要。如果您拒绝提供上述信息，将无法作为24猎平台的企业客户进行平台注册服务。<br />
        您通过我们的服务向其他方提供的共享信息，以及您使用我们的服务时所存储的信息。<br />
        其他方分享的您的信息 其他方使用我们的服务时所提供有关您的共享信息。<br />
        我们获取的您的信息 您使用服务时我们可能会收集如下信息：<br />
        日志信息，指您使用我们的服务时，系统可能通过cookies、web<br />
        beacon或其他方式自动采集的技术信息，包括：设备或软件信息，例如您的移动设备、网页浏览器或用于接入我们服务的其他程序所提供的配置信息、您的IP地址和移动设备所用的版本和设备识别码；在使用我们服务时搜索或浏览的信息，例如您使用的网页搜索词语<br />
        访问的url地址，以及您在使用我们服务时浏览或要求提供的其他信息和内容详情；<br />
        位置信息，指您开启设备定位功能并使用我们基于位置提供的相关服务时，收集的有关您位置的信息，包括：<br />
        1、您通过具有定位功能的移动设备使用我们的服务时，通过GPS或WiFi等方式收集的您的地址位置信息；<br />
        2、您或其他用户提供的包含您所处地址位置的实时信息，例如您提供的账号信息中包含您所在地区信息，您或其他人上传的显示您当前或曾经所处地址位置的共享信息，您或其他人共享的照片包含的地理标记信息；
        当您使用于位置信息相关的服务时，我们会收集您的位置信息，以便您不需要手动输入地址坐标即可获得相关的服务。在您首次使用24猎平台时，我们会提示您选择是否允许24猎平台获取以及何种情况下可以获取您的位置信息。在那您使用24猎平台的过程中，您可以随时通过您的设备设置功能，选择是否允许24猎平台获取以及任何情况下可以获取您的位置信息。获得您的允许后，我们会通过IP地址、GPS来校准您的位置信息，以便于您更准确的选择工作地点、工作就近匹配等服务。您可以关闭定位功能，停止对您的地理位置信息的收集。<br />
        我们可能如何使用信息<br />
        我们可能将在向您提供服务的过程之中所收集的信息用作下列用途：<br />
        1、向您提供服务；<br />
        2、在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；<br />
        3、我们将记录您的订单信息及工作完结状态，我们收集上述信息是基于法律法规要求以及保护您的人身财产安全、依照平台规则处理用户纠纷之需要；<br />
        4、我们可能会以去除身份识别信息的个人信息进行统计分析形成的数据为基础，设计、开发、推广全新的产品及服务；<br />
        5、我们可能将来自24猎平台某项服务的个人信息与来自其他项服务的信息结合起来，做出特征模型并进行用户画像，以为您提供更加个性化、便捷的服务。我们承诺在使用用户画像的过程中，不会侵犯公民、法人和其他组织的合法权益，不会危害国家安全、荣誉和利益；<br />
        6、我们会对我们的服务使用服务情况进行统计，并可能会与公众或第三方分享这些统计信息，但这些统计信息不包括您的任何身份识别信息；<br />
        7、帮助我们更加了解您如何接入和使用我们的服务，从而有针对性的改善现有服务；<br />
        凡是超出与“个人信息的获取”条款声称目的具有直接或合理关联的范围使用您的个人信息，我们会再次向您告知并征得您的明示同意。另外，根据相关法律法规及国家标准，以下情形中，我们可能会收集、使用您的相关个人信息而无需征求您的授权同意：<br />
        1、与个人信息控制者履行法律法规规定的义务相关的;<br />
        2、与国家安全、国防安全直接相关的;<br />
        3、与公共安全、公共卫生、重大公共利益直接相关的;<br />
        4、与刑事侦查、起诉、审判和判决执行等直接相关的:<br />
        5、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的;<br />
        6、所涉及的个人信息是个人信息主体自行向社会公众公开的;<br />
        7、根据个人信息主体要求签订和履行合同所必需的<br />
        8、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道;<br />
        9、维护所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障。<br />
        为了让您有更好的体验、改善我们的服务或您同意的其他用途，在符合相关法律法规的前提下，我们可能将通过某一项服务所收集的信息，以汇集信息或者个性化的方式，用于我们的其他服务。例如，在您使用我们的一项服务时所收集的信息，可能在另一服务中用于您提供特定内容，或向您展示与您相关的、非常普遍推送的信息。如果我们在相关服务中提供了相应选项，您也可以授权我们将该服务所提供和存储的信息用于我们的其他服务。<br />
        您如何访问和控制自己的个人信息<br />
        我们将尽一切可能采取适当的技术手段，保证您可以访问、更新和更正自己的信息或使用我们的服务时提供的其他个人信息。在访问、更新、更正和删除前述信息时，我们可能会要求那您进行身份验证，以保障账户安全。如您希望删除个人信息，可以联系24猎平台客服，提交删除申请。<br />
        我们可能分享的信息<br />
        除以下情形外，未经您同意，我们以及我们的关联公司不会与任何第三方分享您的个人信息。<br />
        我们以及我们的关联公司，可能将您的个人信息与我们的关联公司、合作伙伴及第三方服务供应商、承包商及代理（例如代表我们发出推送通知的通讯服务提供商、为我们提供位置数据的地图服务供应商）分享（他们可能并非位于您所在的法域），用作下列用途：<br />
        1、向您提供我们的服务； 2、实现“我们可能如何使用信息”部分所述目的；<br />
        3、履行我们在本《隐私政策》中的义务和形式我们的权力；<br />
        4、理解、维护和改善我们的服务。<br />
        如我们或我们的关联公司在任何上述第三方分享您的个人信息，我们将努力确保在使用您的个人信息时遵守本《隐私政策》及我们要求其遵守的其他适当的保密和安全措施。<br />
        随着我们业务的持续发展，我们及我们的关联公司有可能进行合并、收购、资产转让或类似的交易，您的个人信息有可能作为此类交易的一部分而被转移。我们将在转移前通知您。<br />
        我们或我们的关联公司还可能为以下需要而保留、保存或披露您的个人信息：<br />
        1、遵守适用的法律法规； 2、遵守法院命令或其他法律程序的规定；<br />
        3、遵守相关政府机关的要求。<br />
        为遵守适用的法律法规、维护社会公共利益，或保护我们的客户、我们和我们的集团公司、其他用户或雇员人身和财产安全或合法权益所合理必须的用途。<br />
        我们仅在本《隐私政策》所述目的所必需的期间和法律法规要求的时限内保留您的个人信息。<br />
        我们使用各种安全技术和程序，以防信息的丢失、不当使用、未经授权阅览或披露。例如，在某些服务中，我们将利用加密技术（例如SSL）来保护您提供的个人信息。但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不能始终保障信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。<br />
        您分享的信息<br />
        我们的多项服务，可让您不仅与自己的社交网络，也与使用该服务的所有用户公开分享您的相关信息，例如，您在我们的服务中所上传或发布的信息（包括您公开的个人信息和建立的信息）、您对其他人上传或发布的信息作出的回应，以及包括与这些信息有关的位置数据和日志信息。使用我们的服务的其他用户也有可能分享与您有关的信息（包括位置数据和日志信息）。<br />
        因此，请您谨慎考虑通过我们的服务上传、发布和交流的信息内容。在一些情况下，您可通过我们某些服务的隐私设定来控制有权浏览您信息的范围。如要求从我们的服务中删除您的相关信息，请通过我们给您提供的方式操作。<br />
        您分享的敏感个人信息<br />
        某些个人信息因其特殊性可能被认为是敏感个人信息，例如您的种族、宗教、个人健康和医疗信息等。相比其他个人信息，敏感个人信息受到更加严格的保护。<br />
        请注意，您在使用我们的服务时所提供、上传或发布的内容和信息，可能会泄露您的敏感个人信息。您需要谨慎地考虑，是否在使用我们的服务时披露相关敏感个人信息。<br />
        您同意按本《隐私政策》所述的目的和方式来处理您的敏感信息。<br />
        我们可能如何收集信息 我们或我们的第三方合作伙伴，可能通过cookies和web<br />
        beacon收集和使用您的信息，并将信息储存为日志信息。<br />
        我们使用自己的cookies和web<br />
        beacon，目的是为您提供更个性化的用户体验和服务，并用于以下用途：<br />
        1、记住您的身份。例如：cookies和web<br />
        beacon有助于我们辨认您作为我们的用户身份，或保存您向我们提供的有关您的喜好或其他信息；<br />
        2、分析您使用我们服务的情况。例如，我们可利用cookies和web<br />
        beacon来了解您使用我们的服务进行什么活动，或哪些网页或服务最受您的欢迎；<br />
        我们为上述目的使用cookies和web beacon的同时，可能将通过cookies和web<br />
        beacon收集的非个人身份信息，经统计加工后提供给广告商或其他合作伙伴，用于分析用户如何使用我们的服务。<br />
        个人信息的保存<br />
        在用户使用24猎平台服务期间，我们会持续保存用户的个人信息。<br />
        当您使用24猎平台服务过程中，存在严重违反法律法规、平台协议、平台规则等情形，您的违法、违约记录及相应的平台信用记录将被永久保存。<br />
        我们收集的您的个人信息，将在中国内地存储和使用。<br />
        我们将采用严格的安全制度以及行业通行的安全技术和程序来确保您的个人信息不丢失、泄露、毁损或被未经授权的访问、使用。<br />
        24猎采取以下安全技术措施保护您的个人信息：<br />
        1、用户个人敏感信息被加密存储在服务器中，并通过数据隔离技术进行存储；<br />
        2、数据传输过程中使用加密传输协议；<br />
        3、严格控制数据访问权限，设立完善的敏感数据访问权限申请和审批制度；<br />
        4、建立数据安全监控和审计制度，进行全面数据安全控制。 信息推送<br />
        您在使用我们的服务时，我们可能使用您的信息向您的设备推送通知。<br />
        我们有可能在必要时（例如因系统维护而暂停某一项服务时）向您发出与服务有关的公告。<br />
        隐私政策的适用范围 我们所有的服务均适用本《隐私政策》。 变更<br />
        我们可能适时修订本《隐私政策》的条款。若您继续使用我们的服务，即表示同意受经修订的本《隐私政策》的约束。<br />
      </div>
      <div class="btjh">
        <br />
        <el-button @click="calk">确认阅读并返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      // msg: 'Welcome to Your Vue.js App'
      edition: 0,
    };
  },
  methods: {
    calk() {
      this.$router.go(-1);
    },
  },
  created() {
    this.edition = this.$route.query.edition;
    console.log(this.edition);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  font-size: 14px;
  background: #fff;
  /* width: 1300px;
  margin: 0 auto; */
  padding: 50px;
}
.main h4 {
  width: 1300px;
  margin: 0 auto;
}

.main section {
  width: 1300px;
  margin: 0 auto;
}

.main p {
  width: 1300px;
  margin: 0 auto;
}

section {
  padding: 10px 0;
}
.btjh {
  text-align: center;
}
</style>
